<style scoped>
.show-content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: lightblue; */
}
</style>
<template>
  <div class="show-content">
    <div class="show-box">
      <div class="show-list" v-for="(item, index) in showList" :key="index" @click="toPath(item.url)">
        <div class="title">名称：{{ item.name }}</div>
        <!-- <div>地址：{{item.url}}</div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showList: [],
    };
  },
  created() {
    this.showList = JSON.parse(this.$route.query.data);
  },
  methods: {
    toPath(url) {
      window.open(url);
    },
  },
};
</script>
